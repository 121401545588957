@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

.nav-list li {
  list-style: none;
  margin-left: 20px;
}

.nav-list li>a {
  transition: all ease-in-out 0.3s;
}

.nav-list li>a:hover {
  color: var(--golden-100);
}

a {
  color: #fff;
  text-decoration: none;
}

.primary-header {
  width: 100%;
  z-index: 5;
  padding-top: 1rem;
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
}

.primary-navigation {
  margin-left: auto;
}

.nav-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  border-radius: 30px;
  top: 0;
  margin: auto;
  justify-content: center;
  width: 85% !important;
  padding-top: 50px;
}

.mobile-nav-toggle {
  display: none;
}

.nav-list {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.nav-list {
  display: flex;
  /* gap: clamp(1rem, 5vw, 3rem); */
  gap: clamp(2rem, 0vw, 3rem);
  font-weight: var(--fw-semi-bold);
}

.nav-list {
  color: white;
}

.nav-list li {
  cursor: pointer;
  line-height: 70px;
  font-size: 16px;
}

.logo {
  display: flex;
  align-items: center !important;
  color: white;
  z-index: 10;
  cursor: pointer;
}

.logo img {
  width: 90px;
}

.logo li {
  list-style: none;
}

.button {
  display: inline-flex;
  backdrop-filter: blur(2px);
  padding: 10px 35px;
  cursor: pointer;
  border: 2px solid;
  border-radius: 5px;
  border-color: red;
  backdrop-filter: blur(15px);
  background: rgba(217, 217, 217, 0.13);
}

.display-sm-hidden {
  display: none;
}

.icon-close,
.icon-hamburger {
  color: white;
  z-index: 10;
}

.toggleDisplay {
  display: none;
}

.menuIcon {
  margin-left: auto;
  display: none;
}

@media (min-width: 50em) {
  .display-md-inline-flex {
    display: inline-flex;
  }
}

@media (max-width: 50em) {
  .primary-header {
    padding-top: 0;
    top: 0;
  }

  .mobile-width {
    width: 100% !important;
  }

  .nav-wrapper {
    border-radius: 0;
    width: 100% !important;
  }

  .nav-list {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    padding-top: 100px;

    font-size: 20px;
    line-height: 27px;
  }

  .primary-navigation {
    display: block;
    position: fixed;
    margin-left: auto;
    background: black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 0.75em rgb(0, 0, 0, 0.05);
    z-index: 6;
    border-radius: 0;
    width: 100%;
    height: 100vh;
    top: 0;
    margin: 0;
    transition: all ease-in-out 0.3s;
    right: -100%;
  }

  .primary-header[data-overlay]::before {
    content: '';
    position: fixed;
    inset: 0;
    z-index: 5;
    /* height: 100vh; */
    background-image: linear-gradient(rgb(0 0 0 / 0.3), rgb(0 0 0 / 0.8));
  }

  .nav-list {
    display: grid;
    gap: var(--size-600);
    text-align: center;
    font-weight: var(--fw-bold);
    line-height: 50px;
  }

  .primary-navigation[data-visible] {
    display: block;
  }

  .toggleDisplay[data-visible] {
    display: block;
  }

  .mobile-nav-toggle {
    z-index: 10;
    display: block;
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0.5em;
  }
}

@media only screen and (max-width: 800px) {
  .menuIcon {
    display: flex;
    z-index: 10;
  }

  .nav-list {
    padding-top: 100px;
    padding-left: 50px;
  }

  .nav-wrapper {
    width: 100%;
    position: fixed;
    background: #000;
    padding-top: 0px;
    padding: 10px 0px;
  }

  .menuIcon {
    margin-right: 20px;
  }

  .logo {
    display: flex;
    align-items: center;
    margin-left: 20px;
    gap: 10px;
  }
}