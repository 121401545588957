@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@font-face {
  font-family: Segoe UI;
  src: url('./assets/font/SegoeUI.ttf');
}

@font-face {
  font-family: Avenir;
  src: url('./assets/font/Avenir.otf');
}

body,
html,
#root {
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
  background: #000;
  overflow-x: hidden;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  /* Specify the desired color for the text */
  -webkit-text-fill-color: var(--grey);
  /* Specify the desired background color */
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

:root {
  --golden-100: #d8aa2e;
  --black-100: #000000;
  --yellow: #d8aa2e;
  --grey: #949494e0;
  --box-bg: #272727d9;
  --white: #ffffff;
}

.create-token-wrapper {
  min-height: 100vh;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.08) 100%), url('./assets/images/createToken/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top, center;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-token-wrapper * {
  margin: 0px;
  padding: 0px;
  font-family: 'Segoe UI', sans-serif;
  list-style: none;
  box-sizing: border-box;
}

.blinking-square {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: var(--yellow);
  animation: blink 2.5s infinite;
  filter: blur(0.7px) brightness(0.6);
}

.square1 {
  top: 60%;
  left: 2%;
}

.square2 {
  bottom: 30%;
  left: 4%;
}

.square3 {
  bottom: 15%;
  left: 20%;
}

.square4 {
  bottom: 15%;
  left: 40%;
}

.square5 {
  bottom: 15%;
  left: 70%;
}

.square6 {
  top: 60%;
  right: 2%;
}

.square7 {
  bottom: 20%;
  right: 4%;
}

.square8 {
  bottom: 40%;
  left: 30%;
}

.square9 {
  bottom: 40%;
  right: 30%;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1300px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  :root {
    font-size: 10px;
  }

  .blinking-square {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 400px) {
  :root {
    font-size: 8px;
  }
}