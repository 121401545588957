.container > h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: var(--yellow);
  margin: 0px auto;
  margin-bottom: 3rem;
  z-index: 1;
}

.container {
  margin-bottom: 10rem;
  padding: 3.5rem;
  border: 1px solid var(--yellow);
}

.container > div {
  border: 1px solid var(--yellow);
  margin-bottom: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.75rem;
  outline: none;
  padding: 0.5rem 1rem;
  color: var(--grey);
  display: flex;
  align-items: center;
  gap: 10rem;
}

.container > div:last-child {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  align-items: center;
  background: none;
  border: none !important;
}

.container > div > p:nth-child(2) {
  color: var(--yellow);
  word-break: break-all;
}

.container > div > p:nth-child(1) {
  width: 5rem;
  color: rgba(255, 255, 255, 0.85);
}

.container button {
  font-family: 'Segoe UI';
  color: var(--white);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--yellow);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.8rem;
  margin: 0px !important;
}
