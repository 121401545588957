.footer {
  padding: 2rem 5%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footHead {
  text-align: center;
  color: var(--golden-100);
  padding: 0;
  margin: 0;
}

.platforms,
.socials {
  display: flex;
  gap: 2.813rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.socials {
  flex-wrap: nowrap;
}

@media (max-width: 500px) {
  .socials {
    gap: 1.5rem;
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* CSS for Text */
.text {
  text-align: center;
  color: var(--golden-100);
  font-size: 20px;
  font-weight: 700;
  line-height: 25.636px;
  letter-spacing: 1.709px;
  margin-bottom: 20px;
}

/* CSS for Social Media Icons */
.social-box {
  margin: 2rem 0;
  gap: 13.73px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-box a {
  text-decoration: none;
}

/* CSS for Email */
.email-box {
  display: flex;
  justify-content: center;
  gap: 40px;
  font-size: 10px;
  font-weight: 700;
}

.email-box a {
  text-decoration: none;
  font-style: italic;
}


.disclaimer {
  width: 650px;
  font-size: 1rem;
}

/* Additional Styling */
@media screen and (max-width: 619px) {
  .text {
    font-size: 24px;
  }


  .email-box .hidden-on-mobile {
    display: none;
  }

  .social-box .hidden-on-desktop {
    display: none;
  }

  .disclaimer {
    width: 90%;
  }
}