.container {
  width: 80%;
  margin: auto;
  background-color: var(--box-bg);
  border-radius: 0.75rem;
  padding: 1rem 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-top: 50px;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.inputContainer > div {
  width: 49%;
}

.container button {
  margin: auto;
  background-color: var(--yellow);
  padding: 0.4rem 1rem;
  border-radius: 0.75rem;
  border: none;
  color: var(--white);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.antiBot {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  width: auto;
  gap: 1rem;
}

.antiBot > input {
  filter: brightness(0.4);
  accent-color: var(--yellow);
}

.antiBot > input:checked {
  filter: brightness(1);
}

.antiBot > label {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: rgba(180, 173, 173, 0.85);
}

.selectContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.selectContainer > label {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem;
  color: var(--yellow);
}

.selectContainer option {
  background-color: #242322;
  color: var(--yellow);
}

.selectContainer > select {
  background: #242322 url('../../assets/images/createToken/arrowDown.png') no-repeat 99% center;
  border-radius: 0.75rem;
  outline: none;
  border: 1px solid var(--yellow);
  padding: 0.5rem 1rem;
  color: var(--grey);
  appearance: none; /* Remove default arrow in Firefox */
  -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
  -moz-appearance: none; /* Remove default arrow in older versions of Firefox */
  cursor: pointer;
}
@media (max-width: 800px) {
  .container {
    margin-top: 120px;
  }
  .inputContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
  }
  .inputContainer > div {
    width: 100%;
  }
}
