.infoBox {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    background: #212121;
border-radius: 20px;
    padding: 1.6rem 2rem;
    box-sizing: border-box;
    
  }
.infoBox ul{

    margin-left: 20px;
  }
  
  .title {
    font-family: 'Avenir';
font-style: normal;
font-weight: 900;
font-size: 24px;
line-height: 26px;
/* or 108% */
color: #D8AA2E;
  }
  
  li{
    font-family: 'Lora';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 26px;
/* or 162% */
text-align: left;
margin-top: 15px;

color: rgba(255, 255, 255, 0.73);
  }