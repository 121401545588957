.swap {
  background-image: url('../../assets/images/home.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px;
  background-color: #10100f;
  color: white;
  min-height: 100vh;
}

.infoContainer {
  width: 35%;
  background: var(--bg-400);
  border-radius: 1.25rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #f4b824;
  background-color: #6463634d;
}

.image {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  margin: 4% 4%;
}

.image > img {
  width: 25px;
  height: 25px;
}

.box {
  display: flex;
  align-self: center;
  width: 90%;
  margin: 1% 0%;
  position: relative;
}
.logo {
  align-self: center;
  background: #f4b824;
  border-radius: 50%;
  padding: 1%;
  margin-bottom: -20px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  zoom: 0.8;
}

.connectButton {
  align-self: center;
  /* margin: 20px auto; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: auto;
}

.connectButton > button {
  padding: 10px 20px;
  border-radius: 2rem;
  border: none;
  background: var(--gradient);
  font-size: large;
  font-weight: 500;
  color: var(--text-white);
  width: 100%;
  font-style: normal;
  font-weight: 900;
}

.connectButton button {
  background: #f4b824 !important;
  border-radius: 2rem !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  font-size: 1rem !important;
  line-height: 1rem !important;
  margin: 0;
  margin-bottom: -20px !important;
}

@media (max-width: 1000px) {
  .infoContainer {
    width: 40%;
  }
}

@media (max-width: 800px) {
  .infoContainer {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .infoContainer {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .logo {
    width: 40px;
    height: 40px;
  }

  .logo > img {
    width: 20px;
    height: 20px;
  }

  .image > img {
    width: 20px;
    height: 20px;
  }
  .connectButton button {
    background: #f4b824 !important;
    border-radius: 2rem !important;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1rem !important;
    line-height: 1rem !important;
    margin: 10px;
  }
}
