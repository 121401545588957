.btn {
    cursor: pointer;
    background: linear-gradient(89.98deg, #F2F37D -9.38%, #D8AA2E 17.23%, #D8AA2E 80.75%, #F2F17C 112.96%);
    border-radius: 10px;
    width: max-content;
    /* height: 65px; */
    align-self: center;
    margin: 5%;
    margin-left: 0;
    border: none;
    padding: 2% 10%;
    /* identical to box height */
    color: #ffffff;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */
    color: #FFFFFF;
}