.modal {
  position: fixed;
  height: 60vh;
  width: 25vw;
  top: calc(0.5 * 100vh);
  left: calc(0.5 * 100vw);
  transform: translate(-50%, -50%);
  z-index: 20;
  padding: 1.125rem;
  font-style: normal;
  background-color: #000004;
  border-radius: 1.25rem;
  padding: 1.125rem 1.563rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.settingmodal {
  position: absolute;
  height: auto;
  width: 30vw;
  top: calc(0.7 * 100vh);
  left: calc(0.5 * 100vw);
  transform: translate(-50%, -50%);
  z-index: 20;
  padding: 1.125rem;
  font-style: normal;
  color: #fff;
  background-color: #000004;
  border-radius: 1.25rem;
  padding: 1.125rem 1.563rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: 900;
  font-size: 0.9rem;
  line-height: 1.688rem;
  color: var(--text-white);
  margin-bottom: 1.25rem;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.inputContainer > input {
  border: none;
  padding: 0.625rem 0.625rem;
  border-radius: 5px;
  background-color: var(--bg-100);
  color: var(--text-white);
  outline: none;
  width: 100%;
  padding-left: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.188rem;
  -moz-appearance: textfield;
}

.inputContainer > input::-webkit-outer-spin-button,
.inputContainer > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn {
  background: #F4B824;
  border-radius: 32.5px;
  height: 35px;
  align-self: center;
  font-style: normal;
  margin: 5% 2% 5% 0%;
  font-weight: normal;
  font-size: 18px;
  line-height: 33px;
  border: none;
  padding: 0% 10%;
  flex: 0.1;
  /* identical to box height */
  color: black;
}

.slippage_section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}



.input_slippage {
  background-color: #252424;
  width: 65%;
  border-radius: 7px;
  border: none;
  height: 30px;
  align-items: center;
  color: #fff;
  flex: 0.7;
  text-align: right;
  padding: 6px 20px;
  color: #F4B824;
}

.input_deadline {
  color: #F4B824;
  background-color: #252424;
  width: 65%;
  border-radius: 7px;
  border: none;
  margin: 3% 2% 3% 0;
  height: 30px;
  align-items: center;
  flex: 0.4;
  text-align: right;
  padding: 6px 20px;
}

.search {
  position: absolute;
  left: 0.625rem;
}

.line {
  height: 1px;
  width: 100%;
  background-color: var(--bg-200);
  margin: 2.688rem 0px;
}

.optionContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 0.938rem;
}

.listContainer {
  display: flex;
  gap: 1.25rem;
  border-radius: 5px;
  align-items: center;
  padding: 5px;
}

.tokenFlex {
  display: flex;
  gap: 15px;
}

.importButton {
  padding: 5px 10px;
  background: #413a69;
  color: #fff;
  border: none;
  margin-right: 20px;
  border-radius: 4px;
}

.searchContainer {
  justify-content: space-between;
  display: flex;
  gap: 1.25rem;
  border-radius: 5px;
  align-items: center;
  padding: 5px;
}

.optionContainer::-webkit-scrollbar {
  width: 5px; /* Set the width of the vertical scrollbar */
}

.optionContainer::-webkit-scrollbar-thumb {
  background-color: var(--bg-400) !important; /* Set the color of the scrollbar thumb */
  border-radius: 0.625rem !important; /* Set the border radius of the scrollbar thumb */
}

.optionContainer::-webkit-scrollbar-track {
  background-color: var(--bg-100); /* Set the color of the scrollbar track */
  border-radius: 0.625rem; /* Set the border radius of the scrollbar track */
}

.searchContainer:hover {
  background-color: var(--text-grey);
}

.listContainer:hover {
  background-color: var(--text-grey);
}

.searchContainer > div > img {
  width: 1.875rem;
  height: 1.875rem;
}

.listContainer > img {
  width: 1.875rem;
  height: 1.875rem;
}

.searchContainer > div > span p:nth-child(1) {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-white);
}

.searchContainer > div > span > p:nth-child(2) {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: rgba(255, 255, 255, 0.71);
}

.listContainer > span > p:nth-child(1) {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--text-white);
}
.listContainer > span > p:nth-child(2) {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: rgba(255, 255, 255, 0.71);
}

@media screen and (max-width: 1000px) {
  .modal {
    width: 60vw;
    height: 50vh;
  }

  .settingmodal {
    height: auto;
    width: 50vw;
    top: calc(0.5 * 100vh);
    left: calc(0.5 * 100vw);
    transform: translate(-50%, -50%);
    z-index: 20;
    padding: 1.125rem;
    font-style: normal;
    border-radius: 1.25rem;
    padding: 1.125rem 1.563rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: black;
  }
}

@media screen and (max-width: 600px) {
  .settingmodal {
    height: auto;
    width: 70vw;
    top: calc(0.5 * 100vh);
    left: calc(0.5 * 100vw);
    transform: translate(-50%, -50%);
    z-index: 20;
    padding: 1.125rem;
    font-style: normal;
    border-radius: 1.25rem;
    padding: 1.125rem 1.563rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .btn {
    border-radius: 32.5px;
    height: 35px;
    align-self: center;
    font-style: normal;
    margin: 5% 2% 5% 0%;
    font-weight: normal;
    font-size: 14px;
    line-height: 33px;
    border: none;
    padding: 0% 8%;
    flex: 0.1;
    /* identical to box height */
    color: black;
    background-color: #F4B824;
  }
}
