.footer {
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 6.125rem;
    font-style: normal;
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 2.063rem;
  }
  .logo {
    width: 73px;
height: 58px;
  }
  .footer > ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
  
  .social > li {
    display: flex;
    gap: 1.25rem;
  }
  
  .social > li > img {
    
    cursor: pointer;
    
  }
  
  @media screen and (max-width: 500px) {
    .footer {
      padding: 2rem 4rem;
    }
  
    .social > li > img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }