.home {
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  white-space: pre-line;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 180px);
  position: relative;
  display: flex;
  font-family: "Lora" !important;
  font-style: normal;
}


.background {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 100%);
  width: 100%;
  height: 100vh;
}

.outer_home {
  background-image: url("../../assets/images/home.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.header {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.56);
  text-align: center;
  justify-content: center;
  
}

.title {
  font-family: "lora";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 70px;
  /* or 109% */
  margin: 20px 0 50px 0;
  text-align: center;

  color: #fff;
}

.homeContent {
  width: 60%;
  padding-bottom: 20px;
}

.point {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  justify-content: center;
}

.purple {
  border-radius: 50%;
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  width: 53px;
  height: 53px;
  background: #eb00ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  border-radius: 50%;
  width: 53px;
  height: 53px;
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00d1ff;
}

.green {
  border-radius: 50%;
  width: 53px;
  height: 53px;
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  background: #00e075;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orange {
  border-radius: 50%;
  width: 53px;
  height: 53px;
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  background: #ff7a00;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Intro*/
.intro {
  background: black;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  align-items: center;
  align-content: center;
  padding: 6rem 0px;
}

.introContent {
  width: 60%;
}

.introHead {
  font-family: "lora";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 55px;
  color: #d8aa2e;
  text-align: center;
}

.introInfo {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  /* or 162% */
  margin-top: 25px;
  text-align: center;

  color: rgba(255, 255, 255, 0.73);
}

.readMore {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  /* or 130% */
  width: 150px;
  margin: auto;
  margin-top: 30px;
  background: transparent;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.workContent {
  width: 70%;
  display: flex;
  flex-direction: row;
  margin-top: 200px;
  position: relative;
  z-index: 0;
}

.left {
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.workHead {
  font-family: "lora";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 55px;
  /* identical to box height */
  color: #d8aa2e;
  z-index: 2;
  position: relative;
  margin-bottom: 30px;
}

.workInfo {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  z-index: 2;
  position: relative;
  /* or 162% */
  text-align: left;

  color: rgba(255, 255, 255, 0.73);
}

.right {
  align-items: center;
  display: flex;
  width: 50%;
}

.workImg {
  margin-top: -50px;
  width: 100%;
  z-index: 2;
  position: relative;
}

/*vision*/
.vision {
  background: #a77f15;
  padding: 5rem 0px;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  align-items: center;
  align-content: center;
}

.visionContent {
  width: 60%;
}

.visionHead {
  font-family: "lora";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 30px;
  line-height: 55px;
  /* identical to box height */

  text-align: center;
  color: #ffffff;
}

.visionInfo {
  font-family: "Lora";
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 30px;
  /* or 125% */

  text-align: center;

  color: #ffffff;
}

.trust {
  background: black;
  padding: 4rem 0px;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  align-items: center;
  align-content: center;
}

.trust_inner {
  padding: 100px 0px;
  display: flex;
}

.bgdiv {
  background-image: url("../../assets/images/glow_bg2.png");
  background-size: cover;
  width: 80% ;
}


.Rcontainer1 {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10%;
  align-items: center;
  padding: 50px 0px;
  margin: auto;
  /* border: solid white; */
}

.Rcontainer2 {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-between;
  gap: 10%;
  align-items: center;
  padding: 50px 0px;
  margin: auto;
  /* border: solid white; */
}

.row {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
}

.readyYou {
  margin-top: 100px;
  gap: 50px;
  display: flex;
}

.col {
  margin-top: 5%;
  flex-direction: column;
}

.trustHead {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  /* identical to box height, or 130% */

  text-align: left;

  color: #d8aa2e;
}

.trustInfo {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* or 162% */
  color: rgba(255, 255, 255, 0.73);
  margin-top: 30px;
}

.trustImg {
  width: 100%;
  height: auto;
}

.imageDiv {
  width: 50%;
  position: relative;
  z-index: 1;
}

.content_trust {
  width: 50%;
  position: relative;
}

.readyHead {
  font-family: "lora";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 55px;
  /* identical to box height */
  text-align: left;
  margin-bottom: 30px;
  color: #d8aa2e;
}

.readyInfo {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  /* or 162% */
  text-align: left;

  color: rgba(255, 255, 255, 0.73);
}

.elipse1 {
  position: absolute;
  right: -50px;
  bottom: -100px;
  width: 300px;
  height: 303px;
  background: #d8aa2e;
  filter: blur(200px);
  z-index: 0;
}

.elipse2 {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 163px;
  height: 163px;
  background: #d8aa2e;
  z-index: 1;
  filter: blur(150px);
}

.elipse3 {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 163px;
  height: 163px;
  background: #d8aa2e;
  z-index: 4;
  filter: blur(170px);
}

.platform {
  display: flex;
  gap: 2.813rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.socials {
  flex-wrap: nowrap;
}

.svg {
  transition: all ease-in-out 0.3s;
}

.svg:hover {
  color: var(--golden-100);
}

.dexview {
  width: 210px;
}

@media (max-width: 1000px) {
  .outer_home {
    background-size: auto;
  }
}

@media (max-width: 769px) {
  .platforms>img {
    width: 100px;
  }

  .title {
    font-size: 32px;
    line-height: 36px;
    text-align: left;
  }

  .home {
    height: 100vh;
  }

  .header {
    font-size: 20px;
    text-align: left;
  }

  .homeContent {
    width: 70%;
  }

  .point {
    justify-content: start;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
  }

  .introContent {
    width: 90%;
    text-align: left;
  }

  .introHead {
    text-align: left;
    font-size: 30px;
  }

  .introInfo {
    text-align: left;
    font-size: 14px;
  }

  .readMore {
    margin: 0px;
    width: auto;
    margin-top: 20px;
  }

  .workContent {
    flex-direction: column-reverse;
  }

  .left,
  .right {
    width: 100%;
  }

  .workImg {
    width: 80%;
    height: 80%;
    margin-top: -80px;
  }

  .workHead {
    margin-bottom: 20px 0;
    font-size: 30px;
  }

  .workContent {
    width: 80%;
  }

  .visionContent {
    width: 80%;
  }

  .visionInfo {
    text-align: left;
    font-size: 14px;
    line-height: 26px;
  }

  .trustHead {
    font-size: 30px;
  }

  .trustInfo {
    font-size: 14px;
    line-height: 26px;
    /* or 162% */
    color: rgba(255, 255, 255, 0.73);
  }

  .trustImg {
    width: 100%;
    height: auto;
    margin-top: 50px;
  }

  .about_flex {
    flex-direction: column-reverse;
  }

  .imageDiv,
  .content_trust {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .readyYou {
    flex-direction: column;
    margin-top: 0px;
    padding-top: 100px;
  }

  .readyHead {
    font-size: 24px;
  }

  .readyInfo {
    font-size: 14px;
  }

  .Rcontainer1 {
    width: 80%;
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    /* border: solid white; */
    text-align: center;
    padding: 30px 0px;
    margin: auto;
  }

  .Rcontainer1 img {
    width: 100%;
  }

  .Rcontainer2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    /* border: solid white; */
    text-align: center;
    padding: 30px 0px;
    margin: auto;
  }

  .Rcontainer2 img {
    width: 100%;
  }

  .readyHead {
    text-align: center;
  }

  .bgdiv {
    background-image: url("../../assets/images/glow_bg2.png");
    background-size: contain;
  }
}

@media (max-width: 390px) {
  .outer_home {
    background-image: url("../../assets/images/home_mobile.svg");
    background-size: auto;
  }
}

@media (min-width: 1700px) {
  .trustImg {
    width: 75%;
    height: auto;
  }
}

/* New Tokenomics Code By Vishwa */
.tokenomics_left {
  width: 100%;

  font-family: "lora";
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.tokenomics_right {
  font-weight: bold;
  width: 100%;
  font-family: "lora";
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tokenomics_right img {
  width: 100%;
}

.tokenomics_left_card {
  gap: 1rem;
  font-family: "lora";
  font-weight: bold;
  font-size: clamp(1.1rem, 20vw + 1rem, 1.3rem);
  min-width: 80%;
  max-width: max-content;
  margin: auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #212121;
  flex-direction: column;
}

.big_font {
  text-align: center;
  font-family: "lora";
  font-weight: bold;
  font-size: clamp(1.1rem, 20vw + 1rem, 1.4rem);
}

.gold {
  color: #D8AA2E;
}

.light_white {
  color: #C3C3C3;
}


@media screen and (max-width:769px) {

  .tokenomics_left,
  .tokenomics_right {
    width: 90%;
  }

  .readyInfo {
    line-height: normal !important;
    font-size: 1.3rem !important;
  }

  .contract_address {
    flex-wrap: wrap !important;
    font-size: clamp(1rem, 20vw, 1.3rem);
  }
}