.infoBox {
  display: flex;
  flex-direction: column;
  background: #242222;
  backdrop-filter: blur(5px);
  border-radius: 0.6rem;
  padding: 1.688rem 2.125rem;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
  border: 1px solid #505050;
}

.title {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  line-height: 22px;
  color: #eeb323;
}

.info {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  color: #eeb323;
}

.infoVal {
  font-family: 'inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  /* identical to box height */

  color: #eeb323;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
}
