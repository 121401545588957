.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.container > label {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem;
  color: var(--yellow);
}

.container span {
  color: red;
}

.special {
  background: rgba(255, 255, 255, 0.1) !important;
  opacity: 0.7;
  border: 1px solid var(--yellow) !important;
}

.container > input {
  background: transparent !important;
  border-radius: 0.75rem;
  outline: none;
  border: 1px solid var(--grey);
  padding: 0.5rem 1rem;
  color: var(--grey);
}
