.nftbody {
  background-image: url("../../assets/images/glow_bg.png");
  /* background-position: left; */
  background-repeat: repeat-y;
  background-size: contain;
  padding-bottom: 30px;
}
.Group1div {
  width: 100%;
  padding: 2% 10%;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap-reverse; */
  align-items: baseline;
  gap: 30px;
}

.Group1div > div:first-child {
  width: 40%;
}
.Group1div > div:nth-child(2) {
  width: 60%;
}

.Group1div img.Group1divImage {
  width: 100%;
  background-blend-mode: lighten;
}

.Group1div img.Group1divGif {
  width: 100%;
}
.Group2div {
  width: 100%;
  padding: 2% 10%;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap-reverse; */
  align-items: center;
  gap: 30px;
}

.Group2div > div:first-child {
  width: 40%;
}
.Group2div > div:nth-child(2) {
  width: 60%;
}

.Group2divImage {
  width: 90%;
  animation: pump 2s infinite;
}

.headingAboutUs {
  color: #e9a702;
  font-family: Lora;
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}
.Group1divsub > div {
  display: grid;
  justify-content: left;
  margin-top: 20px;
}

.bodyAboutUs {
  font-family: Lora;
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: justify;
  color: white;
}

.nftAi {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  color: #eaa803;
  align-items: center;
  padding: 5% 0% 0% 0%;
}

.nftAi > div:first-child {
  width: 50%;
}
.nftAi > div:nth-child(2) {
  width: 30%;
}

.generateInp {
  border: solid 1px;
  padding: 5px 5px 5px 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
}

.generateInp > input {
  font-size: large;
  width: 50%;
  background: none;
  border: none;
  outline: none;
  color: #eaa803;
  padding: 1% 3%;
}

.generateInp > button {
  background: #eaa803;
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  padding: 2% 5%;
  border-radius: 5px;
}

.generateInp2 {
  width: 70%;
  border: solid 1px;
  padding: 5px 5px 5px 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  font-size: large;
  margin-left: 30%;
}

.generateInp2 > input {
  width: 30%;
  text-align: center;
  background: none;
  border: none;
  outline: none;
  font-size: large;
  color: #eaa803;
  border: 1px solid #eaa803;
  padding: 1% 3%;
  border-radius: 5px;
  font-size: large;
}

.generateInp2 > button {
  background: #eaa803;
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  padding: 2% 5%;
  border-radius: 5px;
}

.nft_airight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 20%;
}

.nft_airightImgDiv {
  /* border: solid white; */
  width: 300px;
  height: 400px;
  overflow: hidden;
}

.nft_airightImgDiv > img:first-child {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.nft_airight > img:nth-child(2) {
  width: 30%;
}

.group1btn {
  width: auto;
  padding: 10px 30px;
  background: linear-gradient(0deg, #242424, #242424), linear-gradient(90deg, #eaa803 0%, #a77f15 100%);
  border: 3px solid #a77f15;
  border-radius: 10px;
  color: #a77f15;
  font-size: larger;
  font-weight: bolder;
  margin-top: 8%;
  text-align: center;
  transition: transform 0.3s ease;
}
.group1btn:hover {
  background-color: gray;
  color: white;

  transform: scale(1.2);
}
.group1btn2 {
  position: relative;
  width: auto;
  padding: 10px 30px;
  background: #a77f15;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: larger;
  font-weight: bolder;
  margin-top: 8%;
  text-align: center;
}

.minteddiv {
  position: relative;
  width: 60%;
  margin: auto;
  height: auto;
  border-radius: 20px;
  border: 0.89px solid #ffffff;
  margin-top: -8%;
  background-color: #181818;
  color: #eaa803;
  padding: 20px 0px;
  padding-bottom: 20px;
  text-align: center;
}
.minteddiv > div {
  width: 90%;
  display: flex;
  justify-content: center;
  padding: 10px;
  justify-content: space-between;
  margin: auto;
  font-size: 24px;
  color: white;
  align-items: center;
}
.minteddiv > div > div {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}
.minteddiv > div > div > p {
  font-size: medium;
  color: #eaa803;
}
.mintedinput {
  width: 120px;
  background: none;
  outline: none;
  border: solid 1px #c48d06;
  border-radius: 20px;
  /* padding: 5px; */
  color: #eaa803;
  font-weight: bold;
  text-align: center;
  font-size: large;
}
.mintbtn {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -11%;
  transition: transform 0.3s ease;
}
.mintbtn:hover {
  transform: scale(1.1);
}

.addmintbtn {
  position: relative;
  border: none;
  background: #eaa803;
  padding: 5px;
  border-radius: 10px;
  font-weight: bold;
}

.platforms {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin: 8% 2%;
}
.platforms > a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.platforms > a > img {
  width: 80%;
  margin: auto;
}
.workingHeading {
  width: 95%;
  margin: auto;
  text-align: center;
}
.workingHeading > p {
  margin: 6% 0px;
  font-family: Lora;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  color: #c48d06;
}
.workingHeading > p:first-child {
  margin: 6% 0px;
  font-family: Lora;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  color: #c48d06;
}
.workingHeading2 > p:first-child {
  margin: 6% 0px 0 0;
  font-family: Lora;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  color: #c48d06;
  text-align: center;
}
.workingHeading2 > p:nth-child(2) {
  margin: 5px 0 6% 0px;
  font-family: Lora;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0em;
  color: white;
  text-align: center;
}
.workingDiv {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  flex-wrap: wrap;
  gap: 30px;
}
.workingDiv > div {
  padding: 2%;
  width: 250px;
  height: 250px;
  aspect-ratio: 1/1;
  border-radius: 10px;
  transition: transform 0.3s ease;
  background: linear-gradient(139.75deg, rgba(216, 170, 46, 0.94) 2.42%, rgba(167, 127, 21, 0.94) 94.54%);
}
.workingDiv > div:hover {
  transform: scale(1.1);
}
.workingDiv > div > img {
  width: 50px;
  aspect-ratio: 1;
}
.workingDiv > div > h3 {
  font-size: 20px;
  margin: 20px 0px;
}
.workingDiv > div > p {
  font-size: 12px;
  color: white;
}
.featuresDiv {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: top;
  margin: auto;
}
.featuresDivbig {
  width: 25% !important;
  height: 10%;
  margin: 2% -7%;
}
.featuresDivsmall {
  padding: 2%;
  width: 250px;
  height: 250px;
  aspect-ratio: 1/1;
  border-radius: 10px;
  text-align: center;
}

.featuresDiv > div > img {
  width: 70px;
  aspect-ratio: 1;
  padding: 20px;
  border-radius: 10px;
  background-color: #e9a702;
}
.featuresDiv > div > h3 {
  font-size: 20px;
  margin: 20px 0px;
  color: #eaa803;
}
.featuresDiv > div > p {
  font-size: 12px;
  color: white;
}
.nft {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: auto;
  text-align: center;
}
.nft > div {
  width: auto;
  height: auto;
  border: 1px solid rgba(216, 170, 46, 0.94);
  background-color: #252217;
  border-radius: 10px;
  display: grid;
  justify-content: center;
  padding: 40px 0px;
}
.nft > div > img {
  width: 100%;
  /* aspect-ratio:; */
  padding: 10px;
  border-radius: 10px;
}
.nft > div > p {
  color: white;
  font-size: larger;
  font-weight: bold;
  padding: 10px;
}
.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin: 2% 0% 0% 0%;
}
.footer > div {
  width: 33.33%;
  text-align: center;
}
.footer > div > img {
  width: 40%;
}
.footer > div > p {
  color: white;
}
.footer > div > div {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
@keyframes pump {
  0% {
    transform: scale(1); /* Initial scale */
  }
  50% {
    transform: scale(1.1); /* Maximum scale */
  }
  100% {
    transform: scale(1); /* Back to initial scale */
  }
}

@media (max-width: 800px) {
  .nftbody {
    background-image: url("/src/assets/images/Ellipse1.png"), url("/src/assets/images/Ellipse2.png");
    background-position: left, right;
    background-repeat: repeat;
    background-size: contain;
    padding-bottom: 30px;
    /* border: solid white; */
  }
  .Group1div {
    padding: 2% 5%;
    flex-wrap: wrap-reverse;
    margin-top: 100px;
  }
  .Group1div > div:first-child {
    width: 80%;
  }
  .Group1div > div:nth-child(2) {
    width: 100%;
  }
  .Group2div {
    padding: 2% 5%;
    flex-wrap: wrap-reverse;
  }
  .Group2div > div:first-child {
    width: 80%;
  }
  .Group2div > div:nth-child(2) {
    width: 100%;
  }
  .Group1divsub > div {
    display: grid;
    justify-content: center;
    font-size: large;
  }
  .platforms {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    margin: auto;
    margin: 8% 2%;
  }

  .nftAi {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #eaa803;
    gap: 20px;
    text-align: center;
  }

  .nftAi > div:first-child {
    width: 80%;
  }
  .nftAi > div:nth-child(2) {
    width: 80%;
  }
  .generateInp2 {
    width: 100%;
    margin: auto;
    /* margin-left: 0; */
  }
  .headingAboutUs {
    color: #e9a702;
    font-family: Lora;
    font-size: 36px;
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
  }

  .bodyAboutUs {
    font-family: Lora;
    font-size: 12px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: justify;
    color: white;
  }
  .minteddiv {
    width: 70%;
  }
  .minteddiv > div {
    zoom: 0.7;
    font-size: large;
  }
  .workingDiv > div {
    padding: 5%;
  }
  .featuresDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .featuresDivbig {
    display: none;
    margin: 0;
  }
  .nft {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin: auto;
    padding: 10px;
  }
  .nft > div > img {
    width: 100%;
    aspect-ratio: 1/1;
    /* padding: 10px; */
    border-radius: 10px;
  }
  .nft > div > p {
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
  }

}
