.infoBox {
  display: flex;
  flex-direction: column;
  background: #6463634d;
  border-radius: 0.6rem;
  padding: 1.688rem 2.125rem;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  box-sizing: border-box;
  margin-top: 20px;
  position: relative;
  border: 1px solid #f4b824;
}

.balance {
  position: absolute;
  bottom: 100%;
  right: 3%;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  padding-top: 0%;
  color: white;
  margin-bottom: 10px;
}

.title {
  position: absolute;
  bottom: 100%;
  left: 3%;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  padding-top: 0%;
  color: white;
  margin-bottom: 10px;
}

.btn {
  background: linear-gradient(91.29deg, #6684cd 0.52%, #d65f8b 98.06%);
  border-radius: 32.5px;
  width: max-content;
  height: 35px;
  align-self: center;
  margin: 5%;
  font-style: normal;
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 33px;
  border: none;
  padding: 0% 10%;
  /* identical to box height */

  color: #ffffff;
}
.inputBox {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.inputBox > div {
  /* border: solid; */
  width: 50%;
}
.inputBox > div:nth-child(2) {
  display: flex;
  gap: 0.313rem;
  justify-content: flex-end;
}
.inputBox button {
  padding: 0.313rem 0.625rem;
  border-radius: 0.625rem;
  border: none;
  outline: none;
  color: white;
  background-color: #544625;
  font-size: 0.75rem;
}

.ButtonActive {
  background-color: #f4b824 !important;
}

.input {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 4rem;
  /* line-height: 87px; */
  padding-bottom: 0%;
  color: #f4b824;
  background-color: var(--bg-200);
  border: none;
  outline: none;
}
.input[disabled] {
  color: red !important;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
}
.foot {
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  padding-top: 0%;
  color: white;
}
