.select {
  background: var(--bg-200);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1.3rem;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--text-white);
  display: flex;
  padding: 0.6rem 0.75rem;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  background-color: #544625;
}

.dropdown {
  box-sizing: border-box;
  top: 120%;
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  left: 0%;
}

.dropdown > div {
  box-sizing: border-box;
  gap: 0.3rem;
  display: flex;
  align-items: center;
  background-color: var(--bg-200);
  border-radius: 0.625rem;
  padding: 0.625rem;
  width: 100%;
  border: 1px solid var(--bg-50);
}

.select > div {
  gap: 0.3rem;
  display: flex;
  align-items: center;
}

.arrow {
  margin-left: 0.3rem;
  width: 1rem;
  height: 0.7rem;
}

.coin {
  width: 1.5rem;
  height: 1.5rem;
}
