.roadmap {
  background-color: var(--black-100);
  padding: 3rem 10%;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.roadmap>h2 {
  color: var(--golden-100);
  font-family: Avenir;
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 3.438rem;
  color: var(--golden-100);
  margin: 50px 0px;
}

.box1,
.box2,
.box3 {
  background-image: url("../../assets/images/Ellipse2.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 50px;
  position: relative;
  border: 2px gray;
}

.roadmap>div>ul>li:nth-child(1) {
  list-style: none;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  line-height: 2.75rem;
  color: #ffffff;
  margin-left: -30px;
  margin-bottom: 20px;
}

.roadmap ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.roadmap li {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: #ffffff;
}

.box2 {
  align-items: flex-start;
}

.circle1,
.circle2,
.circle3 {
  position: absolute;
  width: 25px;
  height: 25px;
}

.circle1 {
  left: 50%;
  top: 0%;
  transform: translateY(-50%);
}

.circle2 {
  left: 0%;
  transform: translate(-50%, -50%);
  top: 0%;
}

.circle3 {
  transform: translate(50%, -50%);
  right: 0%;
  top: 0%;
}

.box1 {
  border-style: dashed dashed dashed none;
  position: relative;
  z-index: 1;

}

.box2 {
  position: relative;
}

.elipse4 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 200px;
  background: #D8AA2E;
  z-index: 1;
  filter: blur(200px);
}


.box1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: #000;
  padding-top: 2px;
  z-index: 100;
  margin-top: -2px;
}

.box2 {
  border-style: none none dashed dashed;
}

.box3 {
  border-style: none dashed dashed none;
}

.box3.box5 {
  border-style: none dashed none none;
}

@media (max-width: 700px) {
  .roadmap {
    background-size: contain;
  }

  .box1,
  .box3 {
    padding-right: 10px;
  }

  .box2 {
    padding-left: 20px;
  }

}