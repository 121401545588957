* {
  box-sizing: border-box;
}

.stake {
  background-image: url("../../assets/images/home.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8.5rem 0px 12.5rem 0px;
}
.outer_home {
  background-image: url("../../assets/images/home.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.infoContainer {
  width: 80%;
  margin: 0px auto;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
}

.infoContainer > div {
  width: 48%;
}

.stakeContainer {
  width: 80%;
  align-items: center;
  margin: 0px auto;
  display: flex;
  margin-top: 2.563rem;
  background: #1a1917;
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 2.125rem 3.188rem;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 5.625rem;
}

.leftContent {
  width: 50%;
}

.leftContent > p:nth-child(1) {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-transform: capitalize;

  color: rgba(255, 255, 255, 0.8);
}

.leftContent > p:nth-child(2) {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  /* identical to box height */

  text-transform: capitalize;

  color: #ffffff;
}

.buttonContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1.563rem;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.buttonContainer ~ p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.875rem;
  color: rgba(255, 255, 255, 0.65);
}

.buttonContainer > div {
  padding: 1px;
  height: fit-content;
  width: fit-content;
  border-radius: 0.438rem;
}

.buttonContainer button {
  z-index: 10;
  padding: 0.438rem 0.938rem;
  cursor: pointer;
  border: 1px solid #d8aa2e;
  border-radius: 7px;
  font-family: "Lora";
  flex: 1 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  text-transform: capitalize;
  color: #ffffff;
}

iframe {
  display: none;
}

.balanceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  gap: 1.25rem;
  position: relative;
}

.gradientText {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 26px;
  /* identical to box height */

  text-transform: capitalize;

  color: #d8aa2e;
}

.balanceContainer > p {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #ffffff;
}

.balanceContainer > p:nth-child(5) {
  position: absolute;
  right: 0%;
  text-align: center;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
}

.balanceContainer > p:nth-child(5) > span {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  text-transform: capitalize;
  color: #d8aa2e;
}

.rightContent {
  padding: 3.188rem 1.813rem;
  background: #272727;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.inputBox {
  height: 3.75rem;
  align-items: stretch;
  display: flex;
  padding: 1px;
  background: var(--gradient);
  border: 1px solid #d8aa2e;
  border-radius: 12px;
  margin-bottom: 1.563rem;
}

.inputBox > input,
.inputBox > button {
  height: 100%;
  border: none;
}

.inputBox > button {
  background: #d8aa2e;
  border: 1px solid #d8aa2e;
  border-radius: 0px 12px 12px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-transform: capitalize;
  color: #ffffff;
  border: 1px solid #d8aa2e;
  width: 30%;
  cursor: pointer;
}

.inputBox > input {
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  color: rgba(148, 148, 148, 0.88);
  border-radius: 0.75rem 0px 0px 0.75rem;
  background-color: var(--bg-100);
  outline: none;
  padding: 1.438rem 0.75rem;
  box-sizing: border-box;
  width: 70%;
}

.actionContainer {
  display: flex;
  gap: 0.75rem;
}

.actionContainer > button {
  cursor: pointer;
  padding: 0.5rem 1.313rem;
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-transform: capitalize;

  color: #ffffff;
  margin-bottom: 2.813rem;
  background: #464646;
  border-radius: 100px;
  border: none;
}

.rightContent > div:nth-child(3) {
  font-style: normal;
  border: none;
  align-self: center;
}

.rightContent > div:nth-child(3) button {
  border-radius: 1.594rem;
  /* padding: 0.563rem 2rem; */
  /* font-size: 1.5rem; */
  /* line-height: 2.063rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.buttonContainer > div {
  width: 30%;
}

.buttonContainer button {
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
}

.conBtn {
  background: #d8aa2e;
  border-radius: 25.5px;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}
@media screen and (max-width: 1300px) {
  .balanceContainer > p:nth-child(5) {
    position: absolute;
    right: -0%;
  }
}

@media screen and (max-width: 900px) {
  .stake {
    background-image: url("../../assets/images/createToken/bg_mob.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 80px;
  }
  .stakeContainer {
    flex-direction: column;
    align-items: flex-start;
    display: block;
  }

  .buttonContainer button {
    font-size: 18px;
    font-weight: 600;
    flex: 1;
  }

  .actionContainer > button {
    width: 50%;
    font-weight: 500;
  }

  .rightContent {
    margin-top: 40px;
  }

  .leftContent {
    width: 100%;
  }

  .buttonContainer > div {
    width: 100%;
  }

  .buttonContainer button {
    width: 100%;
    flex: 1;
    padding: 10px 0px;
  }
  .infoContainer {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
  }

  .infoContainer > div {
    width: 100%;
    min-width: fit-content;
  }
  .balanceContainer > p:nth-child(5) {
    position: relative;
    right: 0%;
    text-align: left;
    margin-top: 5%;
  }
}

@media screen and (max-width: 500px) {
  .stakeContainer {
    flex-direction: column;
    align-items: center;
  }
}
