.footer {
  padding: 100px 5%;
  background: var(--black-100);
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.footHead {
  text-align: center;
  color: gold;
  padding: 0;
  margin: 0;
}

.platforms,
.socials {
  display: flex;
  gap: 2.813rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.socials {
  flex-wrap: nowrap;
}

.svg {
  transition: all ease-in-out 0.3s;
}

.svg:hover {
  color: var(--golden-100);
}

.dexview {
  width: 210px;
}


@media screen and (max-width: 500px) {
  .platforms>img {
    width: 100px;
  }

  .socials {
    gap: 1.5rem;
  }
}